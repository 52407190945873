import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { getAuth } from "firebase/auth";


// LOCAL CONFIG

// const firebaseConfig = {
//   apiKey: "AIzaSyB8gzdv3YZfY9vaSNE1SBlIG4vMlEeQ0FI",
//   authDomain: "accelent-v2.firebaseapp.com",
//   projectId: "accelent-v2",
//   storageBucket: "accelent-v2.appspot.com",
//   messagingSenderId: "707646023273",
//   appId: "1:707646023273:web:24cedfefe7c46e9467a4f8"
// };


// NEW LIVE DB CONFIG

const firebaseConfig = {
  apiKey: "AIzaSyChlJ2l8vZ2Lg9efdgPaoovNF49QsTeDTI",
  authDomain: "quadrantgraph-b5413.firebaseapp.com",
  databaseURL: "https://quadrantgraph-b5413-default-rtdb.firebaseio.com",
  projectId: "quadrantgraph-b5413",
  storageBucket: "quadrantgraph-b5413.appspot.com",
  messagingSenderId: "271291366516",
  appId: "1:271291366516:web:394d35b18c133d0359e521"
};

// LIVE CONFIG

// const firebaseConfig = {
//   apiKey: "AIzaSyCKBtNkIpX2YzMQ9gHyLWUbunNITCbEnzw",
//   authDomain: "accelent-603b7.firebaseapp.com",
//   databaseURL: "https://accelent-603b7-default-rtdb.firebaseio.com",
//   projectId: "accelent-603b7",
//   storageBucket: "accelent-603b7.appspot.com",
//   messagingSenderId: "886431932342",
//   appId: "1:886431932342:web:74177bd56ddce38e69ab17"
// };




  const fireDb = firebase.initializeApp(firebaseConfig);
  export const auth = getAuth(fireDb);
  export default fireDb.database().ref()



