import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fireDb from "../firebase";
import { Link } from "react-router-dom";

import "./Candidates.css";
import { toast } from "react-toastify";

import $ from "jquery";

import { Rating } from "@mui/material";
import moment from "moment";

import { FaCircle } from "react-icons/fa";

const initialState = {
  Name: "",
  CandidatePosition: "",
  User: "",
  UserID: "",
  PositionID: "",
  Position: "",
  Company: "",
  Compensation: "",
  City: "",
  State: "",
  Color: "#ff0000",
  DotSize: "",
  CompetencyLevel1Title: "",
  CompetencyLevel2Title: "",
  CompetencyLevel3Title: "",
  CompetencyLevel1CandidateValue: "",
  CompetencyLevel2CandidateValue: "",
  CompetencyLevel3CandidateValue: "",
  LinkedInURL: "",
  HardSkills: "",
  SoftSkills: "",
  UpdatedDateTime: "",
};

const Candidates = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const [users, setUsers] = useState({});
  const [positions, setPositions] = useState({});
  const [search, setSearch] = useState("");

  const [validName, setValidName] = useState("form-control dialog-input");
  const [validCandidatePosition, setvalidCandidatePosition] = useState(
    "form-control dialog-input"
  );
  const [validCompany, setValidCompany] = useState("form-control dialog-input");
  const [validCompensation, setValidCompensation] = useState(
    "form-control dialog-input"
  );
  const [validCity, setValidCity] = useState("form-control dialog-input");
  const [validState, setValidState] = useState("form-control dialog-input");
  const [validColor, setValidColor] = useState("form-control colorPicker");
  const [validDotSize, setValidDotSize] = useState("form-control dialog-input");
  const [validLinkedInURL, setValidLinkedInURL] = useState(
    "form-control dialog-input"
  );
  const [validSoftSkills, setValidSoftSkills] = useState(
    "form-control validSkills"
  );
  const [validHardSkills, setValidHardSkills] = useState(
    "form-control validSkills"
  );
  const [userValid, setUserValid] = useState("form-control dialog-input");
  const [positionValid, setPositionValid] = useState(
    "form-control dialog-input"
  );

  const {
    Name,
    CandidatePosition,
    Company,
    Compensation,
    City,
    State,
    Color,
    DotSize,
    CompetencyLevel1CandidateValue,
    CompetencyLevel2CandidateValue,
    CompetencyLevel3CandidateValue,
    LinkedInURL,
    SoftSkills,
    HardSkills,
    UserID,
    PositionID,
  } = state;

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("Candidates").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  useEffect(() => {
    fireDb.child("Candidates").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, []);

  //   For getting users

  useEffect(() => {
    fireDb.child("users").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setUsers({ ...snapshot.val() });
      } else {
        setUsers({});
      }
    });

    return () => {
      setUsers({});
    };
  }, [id]);

  // For getting Positions

  useEffect(() => {
    fireDb.child("positions").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setPositions({ ...snapshot.val() });
      } else {
        setPositions({});
      }
    });

    return () => {
      setPositions({});
    };
  }, [id]);

  const setTime = () => {
    var dt = moment().format("MMMM Do YYYY, hh:mm a");
    var name = "UpdatedDateTime";
    setState({ ...state, [name]: dt });
  };

  let text, index;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // debugger;
    setValidName("form-control dialog-input");
    setValidCompany("form-control dialog-input");
    setValidCity("form-control dialog-input");
    setValidCompensation("form-control dialog-input");
    setValidColor("form-control colorPicker");
    setValidState("form-control dialog-input");
    setValidDotSize("form-control dialog-input");
    setValidLinkedInURL("form-control dialog-input");
    setvalidCandidatePosition("form-control dialog-input");
    setUserValid("form-control dialog-input");

    index = e.nativeEvent.target.selectedIndex;
    if (index !== undefined) {
      text = e.nativeEvent.target[index].text;
      let UserID = e.target.dataset.userid;
      // let ColorID = e.target.dataset.colorid;

      // setState({
      //   ...state,
      //   [name]: text,
      //   [ColorID]: value,
      // })

      setState({
        ...state,
        [name]: text,
        [UserID]: value,
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handlePositionChange = (e) => {
    const { name, value } = e.target;
    // debugger;
    setPositionValid("form-control dialog-input");
    index = e.nativeEvent.target.selectedIndex;
    if (index !== undefined) {
      text = e.nativeEvent.target[index].text;
      let PositionID = e.target.dataset.positionid;

      setState({
        ...state,
        [name]: text,
        [PositionID]: value,
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // debugger;

    var userValid = $("#user").val();
    var positionValid = $("#position").val();
    if (userValid === "Select User") {
      setUserValid("invalid");
    } else if (positionValid === "Select Position") {
      setPositionValid("invalid");
    } else if (!Name) {
      setValidName("invalid");
    } else if (!Company) {
      setValidCompany("invalid");
    } else if (!CandidatePosition) {
      setvalidCandidatePosition("invalid");
    } else if (!Compensation) {
      setValidCompensation("invalid");
    } else if (!City) {
      setValidCity("invalid");
    } else if (!State) {
      setValidState("invalid");
    } else if (!Color) {
      setValidColor("form-control invalidColor");
    } else if (!DotSize) {
      setValidDotSize("invalid");
    } else if (!LinkedInURL) {
      setValidLinkedInURL("invalid");
    } else if (!HardSkills) {
      setValidHardSkills("invalid");
    } else if (!SoftSkills) {
      setValidSoftSkills("invalid");
    } else {
      if (!id) {
        setState({
          ...state,
          ["CompetencyLevel1Title"]: $("#competency1").val(),
        });
        setState({
          ...state,
          ["CompetencyLevel2Title"]: $("#competency2").val(),
        });
        setState({
          ...state,
          ["CompetencyLevel3Title"]: $("#competency3").val(),
        });

        fireDb.child("Candidates").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Candidate Added Successfully");
          }
        });
      } else {
        setState({
          ...state,
          ["CompetencyLevel1Title"]: $("#competency1").val(),
        });
        setState({
          ...state,
          ["CompetencyLevel2Title"]: $("#competency2").val(),
        });
        setState({
          ...state,
          ["CompetencyLevel3Title"]: $("#competency3").val(),
        });

        fireDb.child(`Candidates/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Candidate Updated Successfully");
          }
        });
      }

      //   setTimeout(() => navigate("/"), 500);
    }
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure, You want to delete that Candidate?")) {
      fireDb.child(`Candidates/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Candidates Deleted Successfully");
        }
      });
    }
  };

  var tempPositions = [],
    UserPosition = [];
  var options = "";
  $("#user").on("change", function () {
    var selectedUserId = $(this).val();
    options = "<option selected>Select Position</option>";

    fireDb.child("positions").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        tempPositions = { ...snapshot.val() };
      }
    });

    Object.keys(tempPositions).map((id, index) => {
      if (tempPositions[id].userid === selectedUserId) {
        UserPosition.push(tempPositions);

        return (options +=
          // '<option value="' + { id } + '">' + tempPositions[id].positionName + "</option>");

          `<option value=${id}> ${tempPositions[id].positionName} </option>`);
      }
    });

    $("#position").empty().append(options);
  });

  // filtring users for searching records
  var filterPositions = [];
  var filterOptions = "";
  $("#userFilter").on("change", function () {
    var selectedUserId = $(this).val();
    filterOptions = `<option selected value="${selectedUserId}">All Searches</option>`;

    fireDb.child("positions").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        filterPositions = { ...snapshot.val() };
      }
    });

    Object.keys(filterPositions).map((id, index) => {
      if (filterPositions[id].userid === selectedUserId) {
        return (filterOptions += `<option value=${id}> ${filterPositions[id].positionName} </option>`);
      }
    });

    $("#positionFilter").empty().append(filterOptions);
  });

  //---------------------

  $("#position").on("change", function () {
    // TODO: Add proper code

    // $('#competency1').val("GeeksForGeeks")

    Object.keys(UserPosition[0]).map((id, index) => {
      if (id === this.children[this.selectedIndex].value) {
        $("#competency1").val(UserPosition[0][id].competencyLable1);
        $("#competency2").val(UserPosition[0][id].competencyLable2);
        $("#competency3").val(UserPosition[0][id].competencyLable3);
      }
    });
  });

  //Counting Total record in table

  var totalCandidates = 0;

  Object.keys(data).map((id, index) => {
    return totalCandidates++;
  });

  // Seaching Records

  const handleFilterChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    if (search === "SelectUser") {
      AllData();
    } else if (search === "SelectPosition") {
      searchPositions();
    } else {
      searchData();
    }
  }, [search]);

  const searchData = () => {
    fireDb
      .child("Candidates")
      .orderByChild("UserID")
      .equalTo(search)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          setData(data);
        }
      });
    fireDb
      .child("Candidates")
      .orderByChild("PositionID")
      .equalTo(search)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          setData(data);
        }
      });
  };

  const searchPositions = () => {
    fireDb
      .child("Candidates")
      .orderByChild("PositionID")
      .equalTo(search)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          setData(data);
        }
      });
  };

  const AllData = () => {
    fireDb.child("Candidates").on("value", (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        setData(data);
      }
    });
  };

  // get position
  var AllPositiondata = [];
  fireDb.child("positions").on(
    "value",
    (snapshot) => {
      if (snapshot.val() !== null) {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          AllPositiondata.push({
            positionID: childKey,
            clientCompany: childData.clientCompany,
            competencyLable1: childData.competencyLable1,
            competencyLable2: childData.competencyLable2,
            competencyLable3: childData.competencyLable3,
            positionName: childData.positionName,
            user: childData.user,
            userid: childData.userid,
          });
        });
      }
    },
    {
      onlyOnce: true,
    }
  );

  // console.log(AllPositiondata);

  const getPositionName = (positionID) => {
    // debugger;
    for (let i = 0; i <= AllPositiondata.length; i++) {
      if (AllPositiondata[i].positionID === positionID) {
        return AllPositiondata[i].positionName;
      }
    }
  };

  return (
    <div className="container-fluid container-custom">
      <hr className="hr"></hr>

      <div className="row mt-2 mb-4">
        <div className="col-4">Home/Candidates</div>
      </div>
      <div className="row candidates-row">
        <h3 className="">Candidates</h3>
        <div className="col-lg-12 candidate-search-container">
          <div className="candidate-search-container1">
            <div className="">
              <form className="selectUsersPositions">
                <div className="col-md-6 me-2">
                  <select
                    className="form-control"
                    id="userFilter"
                    name="UserFilter"
                    data-userid="UserFilterID"
                    onChange={handleFilterChange}
                  >
                    <option selected value="SelectUser">
                      All Users
                    </option>
                    {Object.keys(users).map((id, index) => {
                      return (
                        <option value={id} key={id}>
                          {users[id].userName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-6">
                  <select
                    className="form-control"
                    id="positionFilter"
                    name="PositionFilter"
                    data-positionid="PositionFilterID"
                    onChange={handleFilterChange}
                  >
                    <option selected value="SelectPosition">
                      All Searches
                    </option>
                  </select>
                </div>
              </form>
            </div>

            <div>
              <button
                className="add-candidate-button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                title="Add Candidate"
              >
                +
              </button>
            </div>
          </div>
          <div className="col-2 text-center">
            <h1>{totalCandidates}</h1>

            <p>All Candidates</p>
          </div>
        </div>
      </div>

      <hr className="hr"></hr>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header px-4 pt-4 pb-3 border-0">
              <h5 className="modal-title" id="exampleModalLabel">
                {id ? "Edit Candidate" : "Add Candidate"}
              </h5>
              <button
                title="Close"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body px-4 pt-3 pb-3">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6 mt-2">
                  <label htmlFor="user" className="form-label">
                    User: <span>*</span>
                  </label>
                  <select
                    className={userValid}
                    id="user"
                    name="User"
                    data-userid="UserID"
                    onChange={handleInputChange}
                    onClick={setTime}
                  >
                    <option selected>Select User</option>
                    {Object.keys(users).map((id, index) => {
                      if (UserID === id) {
                        return (
                          <option value={id} key={id} selected>
                            {users[id].userName}
                          </option>
                        );
                      } else {
                        return (
                          <option value={id} key={id}>
                            {users[id].userName}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="position" className="form-label">
                    Position: <span>*</span>
                  </label>
                  <select
                    className={positionValid}
                    id="position"
                    name="Position"
                    data-positionid="PositionID"
                    onChange={handlePositionChange}
                    onClick={setTime}
                  >
                    <option selected>Select Position</option>
                    {Object.keys(positions).map((id, index) => {
                      if (PositionID === id) {
                        return (
                          <option value={id} key={id} selected>
                            {positions[id].positionName}
                          </option>
                        );
                      } else {
                        return (
                          <option value={id} key={id}>
                            {positions[id].positionName}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="name" className="form-label">
                    Name: <span>*</span>
                  </label>
                  <input
                    className={validName}
                    type="text"
                    id="name"
                    name="Name"
                    placeholder="Your Name..."
                    value={Name || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="company" className="form-label">
                    Company: <span>*</span>
                  </label>
                  <input
                    className={validCompany}
                    type="text"
                    id="company"
                    name="Company"
                    placeholder="Your Company..."
                    value={Company || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="candidatePosition" className="form-label">
                    Title: <span>*</span>
                  </label>
                  <input
                    className={validCandidatePosition}
                    type="text"
                    id="candidatePosition"
                    name="CandidatePosition"
                    placeholder="Your Position..."
                    value={CandidatePosition || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="compensation" className="form-label">
                    Compensation: <span>*</span>
                  </label>
                  <input
                    className={validCompensation}
                    type="text"
                    id="compensation"
                    name="Compensation"
                    placeholder="Your Compensation..."
                    value={Compensation || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="city" className="form-label">
                    City: <span>*</span>
                  </label>
                  <input
                    className={validCity}
                    type="text"
                    id="city"
                    name="City"
                    placeholder="Your City..."
                    value={City || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="candidateState" className="form-label">
                    State: <span>*</span>
                  </label>
                  <input
                    className={validState}
                    type="text"
                    id="candidateState"
                    name="State"
                    placeholder="Your State..."
                    value={State || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-3 mt-2">
                  <label htmlFor="color" className="form-label">
                    Color: <span>*</span>
                  </label>
                  <input
                    className={validColor}
                    type="color"
                    id="color"
                    // data-colorid="ColorID"
                    name="Color"
                    value={Color || "#ff0000"}
                    onChange={handleInputChange}
                    list="specificColors"
                    onClick={setTime}
                  />
                  <datalist id="specificColors">
                    <option>#ff0000</option>
                    <option>#00FF00</option>
                    <option>#FFFF00</option>
                  </datalist>
                </div>

                <div className="col-md-3 mt-2">
                  <label htmlFor="dotSize" className="form-label">
                    DotSize: <span>*</span>
                  </label>
                  <input
                    className={validDotSize}
                    type="number"
                    max="10"
                    min="2"
                    id="dotSize"
                    name="DotSize"
                    placeholder="Your dotSize..."
                    value={DotSize || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2 d-none ">
                  <label htmlFor="competency1">Competency 1:</label>
                  <input
                    className="form-control dialog-input"
                    type="text"
                    id="competency1"
                    name="CompetencyLevel1Title"
                    placeholder="Competency 1"
                    readOnly
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="competency1Value" className="form-label">
                    Competency 1 Value:
                  </label>
                  <input
                    className="form-control dialog-input"
                    type="text"
                    id="competency1Value"
                    name="CompetencyLevel1CandidateValue"
                    placeholder="Competency 1 Value"
                    value={CompetencyLevel1CandidateValue || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2 d-none ">
                  <label htmlFor="competency2">Competency 2:</label>
                  <input
                    className="form-control dialog-input"
                    type="text"
                    id="competency2"
                    name="CompetencyLevel2Title"
                    placeholder="Competency 2"
                    readOnly
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2 ">
                  <label htmlFor="competency2Value" className="form-label">
                    Competency 2 Value:
                  </label>
                  <input
                    className="form-control dialog-input"
                    type="text"
                    id="competency2Value"
                    name="CompetencyLevel2CandidateValue"
                    placeholder="Competency 2 Value"
                    value={CompetencyLevel2CandidateValue || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2 d-none ">
                  <label htmlFor="competency3">Competency 3:</label>
                  <input
                    className="form-control dialog-input"
                    type="text"
                    id="competency3"
                    name="CompetencyLevel3Title"
                    placeholder="Competency 3"
                    readOnly
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-6 mt-2">
                  <label htmlFor="competency3Value" className="form-label">
                    Competency 3 Value:
                  </label>
                  <input
                    className="form-control dialog-input"
                    type="text"
                    id="competency3Value"
                    name="CompetencyLevel3CandidateValue"
                    placeholder="Competency 3 Value"
                    value={CompetencyLevel3CandidateValue || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-md-12 mt-2">
                  <label htmlFor="linkedinUrl" className="form-label">
                    linkedin Url: <span>*</span>
                  </label>
                  <input
                    className={validLinkedInURL}
                    type="text"
                    id="linkedinUrl"
                    name="LinkedInURL"
                    placeholder="Your linkedinUrl..."
                    value={LinkedInURL || ""}
                    onChange={handleInputChange}
                    onClick={setTime}
                  />
                </div>

                <div className="col-6 mt-2">
                  <label className="form-label">
                    Hard Skills: <span>*</span>
                  </label>
                  <div className={validHardSkills}>
                    <Rating
                      size="small"
                      max={10}
                      name="HardSkills"
                      value={HardSkills}
                      icon={<FaCircle />}
                      emptyIcon={<FaCircle />}
                      onChange={(e, val) => {
                        const name = "HardSkills",
                          value = val;
                        setState({ ...state, [name]: value });
                        setValidHardSkills("form-control validSkills");
                      }}
                      onClick={setTime}
                    />
                  </div>
                </div>

                <div className="col-6 mt-2">
                  <label className="form-label">
                    Soft Skills: <span>*</span>
                  </label>
                  <div className={validSoftSkills}>
                    <Rating
                      size="small"
                      max={10}
                      name="SoftSkills"
                      value={SoftSkills}
                      icon={<FaCircle />}
                      emptyIcon={<FaCircle />}
                      onChange={(e, val) => {
                        const name = "SoftSkills",
                          value = val;
                        setState({ ...state, [name]: value });
                        setValidSoftSkills("form-control validSkills");
                      }}
                      onClick={setTime}
                    />
                  </div>
                </div>
                <div className="pt-4">
                  <input
                    type="button"
                    className="btn1"
                    data-bs-dismiss="modal"
                    value="Cancel"
                  />
                  <input
                    className="btn2"
                    type="submit"
                    value={id ? "Update" : "Save"}
                  />
                </div>
              </form>
            </div>

            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      <div className="tableContainer">
        <table id="candidatesTable" className="table styled-table">
          <thead>
            <tr>
              <th>Candidate Name</th>
              <th>Candidate Title</th>
              <th>Client Name</th>
              <th>Search Title</th>
              <th>Company</th>
              <th>Compensation</th>
              <th>City</th>
              <th>State</th>
              <th>Color</th>
              <th>DotSize</th>
              <th>Competency 1 Value</th>
              <th>Competency 2 Value</th>
              <th>Competency 3 Value</th>
              <th>Linkedin Url</th>
              <th>Hard Skills</th>
              <th>Soft Skills</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody id="tableBody">
            {Object.keys(data).map((id, index) => {
              return (
                <tr key={id}>
                  <td>{data[id].Name}</td>
                  <td>{data[id].CandidatePosition}</td>
                  <td>{data[id].User}</td>
                  <td>{getPositionName(data[id].PositionID)}</td>
                  <td>{data[id].Company}</td>
                  <td>{data[id].Compensation}</td>
                  <td>{data[id].City}</td>
                  <td>{data[id].State}</td>
                  <td>
                    <div
                      style={{
                        background: data[id].Color,
                        width: 40,
                        height: 30,
                        borderRadius: 10,
                      }}
                    ></div>
                  </td>
                  <td>{data[id].DotSize}</td>
                  <td>{data[id].CompetencyLevel1CandidateValue}</td>
                  <td>{data[id].CompetencyLevel2CandidateValue}</td>
                  <td>{data[id].CompetencyLevel3CandidateValue}</td>
                  <td>{data[id].LinkedInURL}</td>
                  <td>{data[id].HardSkills}</td>
                  <td>{data[id].SoftSkills}</td>

                  <td>
                    <Link to={`/updateCandidate/${id}`}>
                      <button
                        className="btn btn-edit"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        // onClick={loadCandidateValuesOnEdit}
                      >
                        Edit
                      </button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Candidates;
